/*-------------------------------------------------------------------------------MIXINS*/
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*-------------------------------------------------------------------------------COLORS*/
/*---------------------------------------Desktop Styles*/
/*-------------------------------------------------------------------------------MIXINS*/
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*-------------------------------------------------------------------------------COLORS*/
/*-------------------------------------------------------------------------------GENERAL STYLES*/
body {
  background-color: #F9F9F9;
  margin: 0px;
  padding: 0px;
  font-size: 12px;
  letter-spacing: 1px;
  font-family: "Roboto", "Oxygen", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body nav li {
  font-size: 16px;
  font-weight: 700;
}

body .body-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex-grow: 1;
  position: relative;
  padding: 68px 0px 50px;
}

body *:focus {
  outline: none;
}

h1 {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 25px;
  margin: 0px;
  color: #334259;
}

h2 {
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 2px;
  color: #334259;
}

h3 {
  font-size: 16px;
  font-weight: 900;
  border-bottom: 1px solid #F9F9F9;
  color: #334259;
}

h3.green {
  color: #bed12f;
}

h3.purple {
  color: #7e4960;
}

h3.orange {
  color: #f26658;
}

h3.blue {
  color: #334259;
}

h4 {
  margin-top: 5px;
  font-weight: 900;
  margin-bottom: 8px;
}

h5 {
  font-weight: 900;
  font-size: 15px;
  color: #334259;
  margin: 5px 0px 0px;
}

.large-display {
  color: #334259;
  font-size: 24px;
  font-weight: 100;
}

small {
  display: block;
}

ul {
  padding: 0px;
}

ul li {
  list-style-type: none;
}

button, .button {
  padding: 12px 40px;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  display: block;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

button.link, .button.link {
  padding: 0px;
  border: 0;
  border-radius: 0;
  color: #bed12f;
  font-weight: 900;
  font-size: 14px;
  background: none;
}

button.white, .button.white {
  background-color: white;
  color: black;
  border: 1.5px solid #bed12f;
}

button.white:focus, button.white:hover, .button.white:focus, .button.white:hover {
  background-color: #bed12f;
  color: white !important;
}

button.white.active, .button.white.active {
  background-color: #bed12f;
  color: white !important;
  border: 1.5px solid #bed12f;
}

button.green, .button.green {
  background-color: #bed12f;
  color: white;
  border: 1.5px solid #bed12f;
}

button.green:focus, button.green:hover, .button.green:focus, .button.green:hover {
  background-color: white;
  color: black;
}

button.green.active, .button.green.active {
  background-color: white;
  color: black;
  border: 1.5px solid #bed12f;
}

button.green.disabled, .button.green.disabled {
  background-color: lightgrey;
  color: black;
  cursor: not-allowed;
}

button.green.disabled:focus, button.green.disabled:hover, .button.green.disabled:focus, .button.green.disabled:hover {
  background-color: lightgrey;
  color: black;
}

button:disabled, .button:disabled {
  background-color: #F9F9F9;
  border-color: grey;
}

button:disabled:focus, button:disabled:hover, .button:disabled:focus, .button:disabled:hover {
  color: unset;
  background-color: #F9F9F9;
}

a {
  text-decoration: none;
  color: inherit;
}

a.link {
  text-decoration: underline;
  font-weight: 900;
  color: #bed12f;
}

.loading {
  text-align: center;
  font-size: 45px;
}

.loading i {
  animation: rotating 1.5s infinite;
}

.conditions {
  border: 1px solid #a4a4a4;
  padding: 30px;
  height: 400px;
  overflow: scroll;
  background: white;
  margin: 30px auto;
}

.conditions ul {
  padding: revert;
}

.conditions ul li {
  list-style-type: inherit;
}

.accept-conditions label {
  margin-left: 15px;
}

section:first-child {
  padding-bottom: 40px;
  border-bottom: 1px solid lightgrey;
  margin-bottom: 50px;
}

img {
  max-width: 100%;
}

/*header*/
/*-------------------------------------------------------------------------------MIXINS*/
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*-------------------------------------------------------------------------------COLORS*/
/*-------------------------------------------------------------------------------HEADER STYLES*/
header {
  background-color: white;
  padding: 10px 35px;
  border-bottom: 1px solid #D8D8D8;
  position: fixed;
  width: calc(100% - 70px);
  z-index: 999999;
}

header .header-logo {
  max-height: 44px;
  display: inline-block;
}

header .account-controls {
  float: right;
  line-height: 47px;
  position: relative;
}

header .account-controls .input-row {
  display: inline-flex;
  margin-right: 20px;
  margin-left: 5px;
}

/*login form*/
/*-------------------------------------------------------------------------------MIXINS*/
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*-------------------------------------------------------------------------------COLORS*/
/*-------------------------------------------------------------------------------LOGIN STYLES*/
.login-page-wrapper {
  background-image: url("../images/ladyoncomputer.jpg");
  background-size: cover;
  padding: 50px 0px;
  height: 100vh;
}

.login-wrapper {
  max-width: 500px;
  margin: auto;
  text-align: center;
  padding: 30px 15px;
  background: white;
}

.login-wrapper img {
  max-width: 300px;
}

.login-wrapper ul {
  display: inline-block;
  text-align: left;
  margin: 0px;
}

.login-wrapper ul li {
  list-style-type: disc;
  text-align: left;
}

.login-wrapper #login-form, .login-wrapper #forgot-pass, .login-wrapper #reset-form, .login-wrapper #new-user-form {
  max-width: 350px;
  margin: 30px auto;
  text-align: left;
}

.login-wrapper #login-form .forgot-link, .login-wrapper #forgot-pass .forgot-link, .login-wrapper #reset-form .forgot-link, .login-wrapper #new-user-form .forgot-link {
  cursor: pointer;
  font-size: .8em;
  font-style: italic;
}

.login-wrapper #login-form #confirm-pass-status, .login-wrapper #forgot-pass #confirm-pass-status, .login-wrapper #reset-form #confirm-pass-status, .login-wrapper #new-user-form #confirm-pass-status {
  height: 6px;
  width: 50%;
  float: right;
  margin-top: -6px;
}

.login-wrapper #login-form #confirm-pass-status.fail, .login-wrapper #forgot-pass #confirm-pass-status.fail, .login-wrapper #reset-form #confirm-pass-status.fail, .login-wrapper #new-user-form #confirm-pass-status.fail {
  background-color: #f26658;
}

.login-wrapper #login-form #confirm-pass-status.success, .login-wrapper #forgot-pass #confirm-pass-status.success, .login-wrapper #reset-form #confirm-pass-status.success, .login-wrapper #new-user-form #confirm-pass-status.success {
  background-color: #bed12f;
}

/*navigation*/
/*-------------------------------------------------------------------------------MIXINS*/
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*-------------------------------------------------------------------------------COLORS*/
/*-------------------------------------------------------------------------------MENU STYLES*/
nav {
  padding: 30px;
  text-align: center;
}

nav .step-progress .step {
  display: inline-block;
  padding: 0px 30px;
  position: relative;
  text-align: center;
  vertical-align: top;
}

nav .step-progress .step:first-child {
  padding-left: 0px;
}

nav .step-progress .step:last-child {
  padding-right: 0px;
}

nav .step-progress .step img {
  display: block;
  width: 70px;
  margin: auto;
  position: relative;
  z-index: 1;
}

nav .step-progress .step .status-bar {
  height: 10px;
  position: absolute;
  left: 0;
  right: 0;
  top: 35px;
  transform: translateY(-50%);
  z-index: 0;
}

nav .step-progress .step .status-bar.green {
  background-color: #bed12f;
}

nav .step-progress .step .status-bar.blue {
  background-color: #334259;
}

nav .step-progress .step small {
  max-width: 70px;
  margin-top: 10px;
}

.tab-menu {
  display: inline-flex;
  width: 100%;
  background: white;
}

.tab-menu .tab {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  padding: 15px 5px;
  background: #F9F9F9;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
  border-bottom: 2px solid #334259;
}

.tab-menu .tab:hover {
  background-color: #4898bb;
}

.tab-menu .tab.active {
  background-color: #334259;
  color: white;
}

.tab-body .tab {
  display: none;
  background: white;
  padding: 15px 25px;
}

.tab-body .tab.active {
  display: block;
}

.tab-body .tab ul {
  padding-left: 25px;
}

.tab-body .tab ul li {
  list-style-type: disc;
}

/*pages*/
/*-------------------------------------------------------------------------------MIXINS*/
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*-------------------------------------------------------------------------------COLORS*/
.page {
  max-width: 900px;
  margin: 0px auto;
  padding: 0px 30px;
}

.page .page-title {
  text-align: center;
  margin-bottom: 50px;
}

.page .page-title p {
  color: #8D8F90;
  font-size: 18px;
}

.page .input-group {
  margin-bottom: 60px;
}

.page .input-group .sub-group {
  margin-bottom: 30px;
  padding: 0px 0px 20px 25px;
  border-left: 1px solid #8D8F90;
  border-bottom: 1px solid #8D8F90;
}

.page .col-one .input-row {
  width: 100%;
}

.page .col-two .input-row {
  width: calc(50% - 15px);
  display: inline-block;
}

.page .col-two .input-row:nth-of-type(odd) {
  padding-right: 15px;
}

.page .col-two .input-row:nth-of-type(even) {
  padding-left: 15px;
}

.page .col-four .input-row {
  width: calc(25% - 22.5px);
  display: inline-block;
  padding: 0px 15px;
}

.page .col-four .input-row:first-child {
  padding-left: 0px;
}

.page .col-four .input-row:last-child {
  padding-right: 0px;
}

.page .new-credential {
  display: inline-flex;
  margin-bottom: 25px;
}

.page .new-credential button {
  margin-left: 15px;
}

.page #branding-logo button.link {
  float: right;
}

.page .social-link, .page .shaw-service, .page .crm-service {
  display: inline-block;
  width: calc(50% - 15px);
  margin-bottom: 25px;
}

.page .social-link:nth-of-type(odd), .page .shaw-service:nth-of-type(odd), .page .crm-service:nth-of-type(odd) {
  padding-right: 15px;
}

.page .social-link:nth-of-type(even), .page .shaw-service:nth-of-type(even), .page .crm-service:nth-of-type(even) {
  padding-left: 15px;
}

.page .package {
  display: inline-flex;
  width: 100%;
}

.page .package > * {
  text-align: center;
  background: #334259;
  padding: 0px !important;
  margin: 0px 15px !important;
}

.page .package > *:nth-child(1):nth-last-child(1) {
  width: calc(100% - 30px);
  padding: 10px 15px;
}

.page .package > *:nth-child(1):nth-last-child(2), .page .package > *:nth-child(2):nth-last-child(1) {
  width: calc(50% - 30px);
  padding: 10px 15px;
}

.page .package > *:nth-child(1):nth-last-child(3), .page .package > *:nth-child(2):nth-last-child(2), .page .package > *:nth-child(3):nth-last-child(1) {
  width: calc(33.3% - 30px);
  padding: 10px 15px;
}

.page .package > *:nth-child(1):nth-last-child(4), .page .package > *:nth-child(2):nth-last-child(3), .page .package > *:nth-child(3):nth-last-child(2), .page .package > *:nth-child(4):nth-last-child(1) {
  width: calc(25% - 30px);
  padding: 10px 15px;
}

.page .package > *:nth-child(1):nth-last-child(5), .page .package > *:nth-child(2):nth-last-child(4), .page .package > *:nth-child(3):nth-last-child(3), .page .package > *:nth-child(4):nth-last-child(2), .page .package > *:nth-child(5):nth-last-child(1) {
  width: calc(20% - 30px);
  padding: 10px 15px;
}

.page .package > *:nth-child(1):nth-last-child(6), .page .package > *:nth-child(2):nth-last-child(5), .page .package > *:nth-child(3):nth-last-child(4), .page .package > *:nth-child(4):nth-last-child(3), .page .package > *:nth-child(5):nth-last-child(2), .page .package > *:nth-child(6):nth-last-child(1) {
  width: calc(16.66% - 30px);
  padding: 10px 15px;
}

.page .package > *:nth-child(1):nth-last-child(7), .page .package > *:nth-child(2):nth-last-child(6), .page .package > *:nth-child(3):nth-last-child(5), .page .package > *:nth-child(4):nth-last-child(4), .page .package > *:nth-child(5):nth-last-child(3), .page .package > *:nth-child(6):nth-last-child(2), .page .package > *:nth-child(7):nth-last-child(1) {
  width: calc(14.28% - 30px);
  padding: 10px 15px;
}

.page .package > *:nth-child(1):nth-last-child(8), .page .package > *:nth-child(2):nth-last-child(7), .page .package > *:nth-child(3):nth-last-child(6), .page .package > *:nth-child(4):nth-last-child(5), .page .package > *:nth-child(5):nth-last-child(4), .page .package > *:nth-child(6):nth-last-child(3), .page .package > *:nth-child(7):nth-last-child(2), .page .package > *:nth-child(8):nth-last-child(1) {
  width: calc(12.5% - 30px);
  padding: 10px 15px;
}

.page .package > *:nth-child(1):nth-last-child(9), .page .package > *:nth-child(2):nth-last-child(8), .page .package > *:nth-child(3):nth-last-child(7), .page .package > *:nth-child(4):nth-last-child(6), .page .package > *:nth-child(5):nth-last-child(5), .page .package > *:nth-child(6):nth-last-child(4), .page .package > *:nth-child(7):nth-last-child(3), .page .package > *:nth-child(8):nth-last-child(2), .page .package > *:nth-child(9):nth-last-child(1) {
  width: calc(11.11% - 30px);
  padding: 10px 15px;
}

.page .package > *:nth-child(1):nth-last-child(10), .page .package > *:nth-child(2):nth-last-child(9), .page .package > *:nth-child(3):nth-last-child(8), .page .package > *:nth-child(4):nth-last-child(7), .page .package > *:nth-child(5):nth-last-child(6), .page .package > *:nth-child(6):nth-last-child(5), .page .package > *:nth-child(7):nth-last-child(4), .page .package > *:nth-child(8):nth-last-child(3), .page .package > *:nth-child(9):nth-last-child(2), .page .package > *:nth-child(10):nth-last-child(1) {
  width: calc(10% - 30px);
  padding: 10px 15px;
}

.page .package > *:nth-child(1):nth-last-child(11), .page .package > *:nth-child(2):nth-last-child(10), .page .package > *:nth-child(3):nth-last-child(9), .page .package > *:nth-child(4):nth-last-child(8), .page .package > *:nth-child(5):nth-last-child(7), .page .package > *:nth-child(6):nth-last-child(6), .page .package > *:nth-child(7):nth-last-child(5), .page .package > *:nth-child(8):nth-last-child(4), .page .package > *:nth-child(9):nth-last-child(3), .page .package > *:nth-child(10):nth-last-child(2), .page .package > *:nth-child(11):nth-last-child(1) {
  width: calc(9.09% - 30px);
  padding: 10px 15px;
}

.page .package > *:nth-child(1):nth-last-child(12), .page .package > *:nth-child(2):nth-last-child(11), .page .package > *:nth-child(3):nth-last-child(10), .page .package > *:nth-child(4):nth-last-child(9), .page .package > *:nth-child(5):nth-last-child(8), .page .package > *:nth-child(6):nth-last-child(7), .page .package > *:nth-child(7):nth-last-child(6), .page .package > *:nth-child(8):nth-last-child(5), .page .package > *:nth-child(9):nth-last-child(4), .page .package > *:nth-child(10):nth-last-child(3), .page .package > *:nth-child(11):nth-last-child(2), .page .package > *:nth-child(12):nth-last-child(1) {
  width: calc(8.3333% - 30px);
  padding: 10px 15px;
}

@media only screen and (max-width: 749px) {
  .page .package > *:nth-child(1):nth-last-child(2), .page .package > *:nth-child(2):nth-last-child(1) {
    width: 100%;
    padding: 10px 15px;
  }
  .page .package > *:nth-child(1):nth-last-child(3), .page .package > *:nth-child(2):nth-last-child(2), .page .package > *:nth-child(3):nth-last-child(1) {
    width: 100%;
    padding: 10px 15px;
  }
  .page .package > *:nth-child(1):nth-last-child(4), .page .package > *:nth-child(2):nth-last-child(3), .page .package > *:nth-child(3):nth-last-child(2), .page .package > *:nth-child(4):nth-last-child(1) {
    width: 100%;
    padding: 10px 15px;
  }
  .page .package > *:nth-child(1):nth-last-child(5), .page .package > *:nth-child(2):nth-last-child(4), .page .package > *:nth-child(3):nth-last-child(3), .page .package > *:nth-child(4):nth-last-child(2), .page .package > *:nth-child(5):nth-last-child(1) {
    width: 100%;
    padding: 10px 15px;
  }
  .page .package > *:nth-child(1):nth-last-child(6), .page .package > *:nth-child(2):nth-last-child(5), .page .package > *:nth-child(3):nth-last-child(4), .page .package > *:nth-child(4):nth-last-child(3), .page .package > *:nth-child(5):nth-last-child(2), .page .package > *:nth-child(6):nth-last-child(1) {
    width: 100%;
    padding: 10px 15px;
  }
  .page .package > *:nth-child(1):nth-last-child(7), .page .package > *:nth-child(2):nth-last-child(6), .page .package > *:nth-child(3):nth-last-child(5), .page .package > *:nth-child(4):nth-last-child(4), .page .package > *:nth-child(5):nth-last-child(3), .page .package > *:nth-child(6):nth-last-child(2), .page .package > *:nth-child(7):nth-last-child(1) {
    width: 100%;
    padding: 10px 15px;
  }
  .page .package > *:nth-child(1):nth-last-child(8), .page .package > *:nth-child(2):nth-last-child(7), .page .package > *:nth-child(3):nth-last-child(6), .page .package > *:nth-child(4):nth-last-child(5), .page .package > *:nth-child(5):nth-last-child(4), .page .package > *:nth-child(6):nth-last-child(3), .page .package > *:nth-child(7):nth-last-child(2), .page .package > *:nth-child(8):nth-last-child(1) {
    width: 100%;
    padding: 10px 15px;
  }
  .page .package > *:nth-child(1):nth-last-child(9), .page .package > *:nth-child(2):nth-last-child(8), .page .package > *:nth-child(3):nth-last-child(7), .page .package > *:nth-child(4):nth-last-child(6), .page .package > *:nth-child(5):nth-last-child(5), .page .package > *:nth-child(6):nth-last-child(4), .page .package > *:nth-child(7):nth-last-child(3), .page .package > *:nth-child(8):nth-last-child(2), .page .package > *:nth-child(9):nth-last-child(1) {
    width: 100%;
    padding: 10px 15px;
  }
  .page .package > *:nth-child(1):nth-last-child(10), .page .package > *:nth-child(2):nth-last-child(9), .page .package > *:nth-child(3):nth-last-child(8), .page .package > *:nth-child(4):nth-last-child(7), .page .package > *:nth-child(5):nth-last-child(6), .page .package > *:nth-child(6):nth-last-child(5), .page .package > *:nth-child(7):nth-last-child(4), .page .package > *:nth-child(8):nth-last-child(3), .page .package > *:nth-child(9):nth-last-child(2), .page .package > *:nth-child(10):nth-last-child(1) {
    width: 100%;
    padding: 10px 15px;
  }
  .page .package > *:nth-child(1):nth-last-child(11), .page .package > *:nth-child(2):nth-last-child(10), .page .package > *:nth-child(3):nth-last-child(9), .page .package > *:nth-child(4):nth-last-child(8), .page .package > *:nth-child(5):nth-last-child(7), .page .package > *:nth-child(6):nth-last-child(6), .page .package > *:nth-child(7):nth-last-child(5), .page .package > *:nth-child(8):nth-last-child(4), .page .package > *:nth-child(9):nth-last-child(3), .page .package > *:nth-child(10):nth-last-child(2), .page .package > *:nth-child(11):nth-last-child(1) {
    width: 100%;
    padding: 10px 15px;
  }
  .page .package > *:nth-child(1):nth-last-child(12), .page .package > *:nth-child(2):nth-last-child(11), .page .package > *:nth-child(3):nth-last-child(10), .page .package > *:nth-child(4):nth-last-child(9), .page .package > *:nth-child(5):nth-last-child(8), .page .package > *:nth-child(6):nth-last-child(7), .page .package > *:nth-child(7):nth-last-child(6), .page .package > *:nth-child(8):nth-last-child(5), .page .package > *:nth-child(9):nth-last-child(4), .page .package > *:nth-child(10):nth-last-child(3), .page .package > *:nth-child(11):nth-last-child(2), .page .package > *:nth-child(12):nth-last-child(1) {
    width: 100%;
    padding: 10px 15px;
  }
}

.page .package > * .heading {
  padding: 20px;
}

.page .package > * .heading h2, .page .package > * .heading p {
  margin: 0px;
}

.page .package > * .pricing {
  padding: 15px;
}

.page .package > * .pricing .monthly {
  padding-bottom: 10px;
  border-bottom: 1px solid grey;
}

.page .package > * .pricing .monthly span {
  font-size: 40px;
  font-weight: 900;
}

.page .package > * .pricing .setup {
  padding: 10px 0px;
}

.page .package > * .pricing .setup span {
  font-size: 30px;
}

.page .package > * button {
  margin: 20px auto 0px;
  color: #334259;
}

.page .package * {
  color: white;
}

.page .package .orange .heading {
  background: #f26658;
}

.page .package .purple .heading {
  background: #7e4960;
}

.page .package .green .heading {
  background: #bed12f;
}

.page .package .blue .heading {
  background: #4898bb;
}

.page .package.total {
  margin-top: 40px;
}

.page .ad-opt-out {
  text-align: right;
  padding: 10px 17px;
}

.page .ad-opt-out button {
  display: inline;
}

.page .check-out {
  display: inline-flex;
  width: 100%;
}

.page .check-out > *:nth-child(1):nth-last-child(1) {
  width: calc(100% - 30px);
  padding: 10px 15px;
}

.page .check-out > *:nth-child(1):nth-last-child(2), .page .check-out > *:nth-child(2):nth-last-child(1) {
  width: calc(50% - 30px);
  padding: 10px 15px;
}

.page .check-out > *:nth-child(1):nth-last-child(3), .page .check-out > *:nth-child(2):nth-last-child(2), .page .check-out > *:nth-child(3):nth-last-child(1) {
  width: calc(33.3% - 30px);
  padding: 10px 15px;
}

.page .check-out > *:nth-child(1):nth-last-child(4), .page .check-out > *:nth-child(2):nth-last-child(3), .page .check-out > *:nth-child(3):nth-last-child(2), .page .check-out > *:nth-child(4):nth-last-child(1) {
  width: calc(25% - 30px);
  padding: 10px 15px;
}

.page .check-out > *:nth-child(1):nth-last-child(5), .page .check-out > *:nth-child(2):nth-last-child(4), .page .check-out > *:nth-child(3):nth-last-child(3), .page .check-out > *:nth-child(4):nth-last-child(2), .page .check-out > *:nth-child(5):nth-last-child(1) {
  width: calc(20% - 30px);
  padding: 10px 15px;
}

.page .check-out > *:nth-child(1):nth-last-child(6), .page .check-out > *:nth-child(2):nth-last-child(5), .page .check-out > *:nth-child(3):nth-last-child(4), .page .check-out > *:nth-child(4):nth-last-child(3), .page .check-out > *:nth-child(5):nth-last-child(2), .page .check-out > *:nth-child(6):nth-last-child(1) {
  width: calc(16.66% - 30px);
  padding: 10px 15px;
}

.page .check-out > *:nth-child(1):nth-last-child(7), .page .check-out > *:nth-child(2):nth-last-child(6), .page .check-out > *:nth-child(3):nth-last-child(5), .page .check-out > *:nth-child(4):nth-last-child(4), .page .check-out > *:nth-child(5):nth-last-child(3), .page .check-out > *:nth-child(6):nth-last-child(2), .page .check-out > *:nth-child(7):nth-last-child(1) {
  width: calc(14.28% - 30px);
  padding: 10px 15px;
}

.page .check-out > *:nth-child(1):nth-last-child(8), .page .check-out > *:nth-child(2):nth-last-child(7), .page .check-out > *:nth-child(3):nth-last-child(6), .page .check-out > *:nth-child(4):nth-last-child(5), .page .check-out > *:nth-child(5):nth-last-child(4), .page .check-out > *:nth-child(6):nth-last-child(3), .page .check-out > *:nth-child(7):nth-last-child(2), .page .check-out > *:nth-child(8):nth-last-child(1) {
  width: calc(12.5% - 30px);
  padding: 10px 15px;
}

.page .check-out > *:nth-child(1):nth-last-child(9), .page .check-out > *:nth-child(2):nth-last-child(8), .page .check-out > *:nth-child(3):nth-last-child(7), .page .check-out > *:nth-child(4):nth-last-child(6), .page .check-out > *:nth-child(5):nth-last-child(5), .page .check-out > *:nth-child(6):nth-last-child(4), .page .check-out > *:nth-child(7):nth-last-child(3), .page .check-out > *:nth-child(8):nth-last-child(2), .page .check-out > *:nth-child(9):nth-last-child(1) {
  width: calc(11.11% - 30px);
  padding: 10px 15px;
}

.page .check-out > *:nth-child(1):nth-last-child(10), .page .check-out > *:nth-child(2):nth-last-child(9), .page .check-out > *:nth-child(3):nth-last-child(8), .page .check-out > *:nth-child(4):nth-last-child(7), .page .check-out > *:nth-child(5):nth-last-child(6), .page .check-out > *:nth-child(6):nth-last-child(5), .page .check-out > *:nth-child(7):nth-last-child(4), .page .check-out > *:nth-child(8):nth-last-child(3), .page .check-out > *:nth-child(9):nth-last-child(2), .page .check-out > *:nth-child(10):nth-last-child(1) {
  width: calc(10% - 30px);
  padding: 10px 15px;
}

.page .check-out > *:nth-child(1):nth-last-child(11), .page .check-out > *:nth-child(2):nth-last-child(10), .page .check-out > *:nth-child(3):nth-last-child(9), .page .check-out > *:nth-child(4):nth-last-child(8), .page .check-out > *:nth-child(5):nth-last-child(7), .page .check-out > *:nth-child(6):nth-last-child(6), .page .check-out > *:nth-child(7):nth-last-child(5), .page .check-out > *:nth-child(8):nth-last-child(4), .page .check-out > *:nth-child(9):nth-last-child(3), .page .check-out > *:nth-child(10):nth-last-child(2), .page .check-out > *:nth-child(11):nth-last-child(1) {
  width: calc(9.09% - 30px);
  padding: 10px 15px;
}

.page .check-out > *:nth-child(1):nth-last-child(12), .page .check-out > *:nth-child(2):nth-last-child(11), .page .check-out > *:nth-child(3):nth-last-child(10), .page .check-out > *:nth-child(4):nth-last-child(9), .page .check-out > *:nth-child(5):nth-last-child(8), .page .check-out > *:nth-child(6):nth-last-child(7), .page .check-out > *:nth-child(7):nth-last-child(6), .page .check-out > *:nth-child(8):nth-last-child(5), .page .check-out > *:nth-child(9):nth-last-child(4), .page .check-out > *:nth-child(10):nth-last-child(3), .page .check-out > *:nth-child(11):nth-last-child(2), .page .check-out > *:nth-child(12):nth-last-child(1) {
  width: calc(8.3333% - 30px);
  padding: 10px 15px;
}

@media only screen and (max-width: 749px) {
  .page .check-out > *:nth-child(1):nth-last-child(2), .page .check-out > *:nth-child(2):nth-last-child(1) {
    width: 100%;
    padding: 10px 15px;
  }
  .page .check-out > *:nth-child(1):nth-last-child(3), .page .check-out > *:nth-child(2):nth-last-child(2), .page .check-out > *:nth-child(3):nth-last-child(1) {
    width: 100%;
    padding: 10px 15px;
  }
  .page .check-out > *:nth-child(1):nth-last-child(4), .page .check-out > *:nth-child(2):nth-last-child(3), .page .check-out > *:nth-child(3):nth-last-child(2), .page .check-out > *:nth-child(4):nth-last-child(1) {
    width: 100%;
    padding: 10px 15px;
  }
  .page .check-out > *:nth-child(1):nth-last-child(5), .page .check-out > *:nth-child(2):nth-last-child(4), .page .check-out > *:nth-child(3):nth-last-child(3), .page .check-out > *:nth-child(4):nth-last-child(2), .page .check-out > *:nth-child(5):nth-last-child(1) {
    width: 100%;
    padding: 10px 15px;
  }
  .page .check-out > *:nth-child(1):nth-last-child(6), .page .check-out > *:nth-child(2):nth-last-child(5), .page .check-out > *:nth-child(3):nth-last-child(4), .page .check-out > *:nth-child(4):nth-last-child(3), .page .check-out > *:nth-child(5):nth-last-child(2), .page .check-out > *:nth-child(6):nth-last-child(1) {
    width: 100%;
    padding: 10px 15px;
  }
  .page .check-out > *:nth-child(1):nth-last-child(7), .page .check-out > *:nth-child(2):nth-last-child(6), .page .check-out > *:nth-child(3):nth-last-child(5), .page .check-out > *:nth-child(4):nth-last-child(4), .page .check-out > *:nth-child(5):nth-last-child(3), .page .check-out > *:nth-child(6):nth-last-child(2), .page .check-out > *:nth-child(7):nth-last-child(1) {
    width: 100%;
    padding: 10px 15px;
  }
  .page .check-out > *:nth-child(1):nth-last-child(8), .page .check-out > *:nth-child(2):nth-last-child(7), .page .check-out > *:nth-child(3):nth-last-child(6), .page .check-out > *:nth-child(4):nth-last-child(5), .page .check-out > *:nth-child(5):nth-last-child(4), .page .check-out > *:nth-child(6):nth-last-child(3), .page .check-out > *:nth-child(7):nth-last-child(2), .page .check-out > *:nth-child(8):nth-last-child(1) {
    width: 100%;
    padding: 10px 15px;
  }
  .page .check-out > *:nth-child(1):nth-last-child(9), .page .check-out > *:nth-child(2):nth-last-child(8), .page .check-out > *:nth-child(3):nth-last-child(7), .page .check-out > *:nth-child(4):nth-last-child(6), .page .check-out > *:nth-child(5):nth-last-child(5), .page .check-out > *:nth-child(6):nth-last-child(4), .page .check-out > *:nth-child(7):nth-last-child(3), .page .check-out > *:nth-child(8):nth-last-child(2), .page .check-out > *:nth-child(9):nth-last-child(1) {
    width: 100%;
    padding: 10px 15px;
  }
  .page .check-out > *:nth-child(1):nth-last-child(10), .page .check-out > *:nth-child(2):nth-last-child(9), .page .check-out > *:nth-child(3):nth-last-child(8), .page .check-out > *:nth-child(4):nth-last-child(7), .page .check-out > *:nth-child(5):nth-last-child(6), .page .check-out > *:nth-child(6):nth-last-child(5), .page .check-out > *:nth-child(7):nth-last-child(4), .page .check-out > *:nth-child(8):nth-last-child(3), .page .check-out > *:nth-child(9):nth-last-child(2), .page .check-out > *:nth-child(10):nth-last-child(1) {
    width: 100%;
    padding: 10px 15px;
  }
  .page .check-out > *:nth-child(1):nth-last-child(11), .page .check-out > *:nth-child(2):nth-last-child(10), .page .check-out > *:nth-child(3):nth-last-child(9), .page .check-out > *:nth-child(4):nth-last-child(8), .page .check-out > *:nth-child(5):nth-last-child(7), .page .check-out > *:nth-child(6):nth-last-child(6), .page .check-out > *:nth-child(7):nth-last-child(5), .page .check-out > *:nth-child(8):nth-last-child(4), .page .check-out > *:nth-child(9):nth-last-child(3), .page .check-out > *:nth-child(10):nth-last-child(2), .page .check-out > *:nth-child(11):nth-last-child(1) {
    width: 100%;
    padding: 10px 15px;
  }
  .page .check-out > *:nth-child(1):nth-last-child(12), .page .check-out > *:nth-child(2):nth-last-child(11), .page .check-out > *:nth-child(3):nth-last-child(10), .page .check-out > *:nth-child(4):nth-last-child(9), .page .check-out > *:nth-child(5):nth-last-child(8), .page .check-out > *:nth-child(6):nth-last-child(7), .page .check-out > *:nth-child(7):nth-last-child(6), .page .check-out > *:nth-child(8):nth-last-child(5), .page .check-out > *:nth-child(9):nth-last-child(4), .page .check-out > *:nth-child(10):nth-last-child(3), .page .check-out > *:nth-child(11):nth-last-child(2), .page .check-out > *:nth-child(12):nth-last-child(1) {
    width: 100%;
    padding: 10px 15px;
  }
}

.page .check-out .order-summary h3, .page .check-out .cc-form h3 {
  text-align: center;
}

.page#pageThree h1 {
  text-align: center;
  text-decoration: underline;
}

.page#pageThree .page-title {
  margin-bottom: 20px;
}

/*---------------------------------------------------AUTH.NET FORM STYLING*/
.paymentInformation .cardholder-name {
  padding-left: 15px;
}

.paymentInformation .cardholder-name input {
  background: #334259;
  color: white;
  width: 90%;
  height: 25px;
  border: 1px solid #334259;
  padding-left: 15px;
  border-radius: 3px;
}

.paymentInformation > div > div {
  padding: 15px;
  background: transparent;
}

.paymentInformation > div > div > div:nth-child(4) button,
.paymentInformation > div > div > div:nth-child(4) button:disabled {
  background: #bed12f;
  color: #fff;
  border: 1px solid #bed12f;
  box-shadow: none;
}

.paymentInformation > div > div > div:nth-child(4) button:hover {
  background: transparent;
  color: #bed12f;
}

.paymentInformation > div > div > div:nth-child(1) {
  width: 100%;
  margin-bottom: 8px;
}

.paymentInformation > div > div > div:nth-child(2) {
  padding-left: 0px;
  width: 50%;
}

.paymentInformation > div > div > div:nth-child(3) {
  width: 50%;
}

.paymentInformation > div > div > div:nth-child(1) > div,
.paymentInformation > div > div > div:nth-child(2) > div,
.paymentInformation > div > div > div:nth-child(3) > div {
  background: #334259;
}

/*-------------------------------------------------------------------------------MIXINS*/
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*-------------------------------------------------------------------------------COLORS*/
.input-row {
  margin: 5px 0px 15px;
  width: 100%;
}

.input-row.checkbox {
  display: inline-flex;
  width: 100%;
}

.input-row.checkbox label {
  width: 90%;
}

.input-row.checkbox > div {
  width: 10%;
  text-align: right;
}

.input-row label {
  display: block;
  margin-bottom: 7px;
  color: #8D8F90;
  text-transform: uppercase;
}

.input-row input:not([type="checkbox"]):not([type="button"]):not([type="file"]) {
  display: block;
  border: 1px solid;
  width: calc(100% - 32px);
  background-color: white;
  padding: 15px;
}

.input-row input:not([type="checkbox"]):not([type="button"]):not([type="file"]):focus {
  border: 1px solid #354655;
}

.input-row input:not([type="checkbox"]):not([type="button"]):not([type="file"]).error {
  border: 1px solid red;
}

.input-row input:not([type="checkbox"]):not([type="button"]):not([type="file"]):disabled {
  background: lightgrey;
  border: 1px solid lightgrey;
}

.input-row input:not([type="checkbox"]):not([type="button"]):not([type="file"]):required {
  border: 1px solid #bed12f;
}

.input-row .file {
  display: inline-flex;
  width: 100%;
}

.input-row .file input {
  display: inline-block;
  width: calc(50% - 32px);
  height: max-content;
  border: 1px solid;
  padding: 15px;
  background-color: white;
}

.input-row .file img {
  width: calc(50% - 30px);
  margin-left: 30px;
}

.input-table-row {
  display: inline-flex;
  width: calc(100% - 22px);
  padding: 10px;
  border: 1px solid lightgrey;
}

.input-table-row > a {
  padding-right: 15px;
  width: 40%;
}

.input-table-row .input-row {
  width: 60%;
  text-align: right;
}

.input-table-row * {
  margin: 0px !important;
}

input {
  padding: 5px 10px;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  font-size: 1em;
  margin: 0px;
}

.select {
  position: relative;
}

.select select {
  appearance: unset;
  -webkit-appearance: unset;
  padding: 15px 35px 15px 15px;
  border-radius: 30px;
  background-color: white;
  border: 1px solid;
  width: 100%;
  cursor: pointer;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.select select:hover, .select select:focus {
  background-color: #bed12f;
  color: white;
}

.select select:required {
  border: 1px solid #bed12f;
}

.select:after {
  content: '';
  position: absolute;
  right: 20px;
  top: 23px;
  width: 0px;
  height: 0px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid;
}

/*footer*/
/*-------------------------------------------------------------------------------MIXINS*/
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*-------------------------------------------------------------------------------COLORS*/
/*-------------------------------------------------------------------------------FOOTER STYLES*/
#footer-banner {
  background: white;
}

#footer-banner #step-navigation {
  text-align: left;
  max-width: 900px;
  margin: 0px auto;
  padding: 30px;
}

#footer-banner #step-navigation .help-number {
  display: inline-flex;
  width: calc(100% - 245px);
  vertical-align: middle;
}

#footer-banner #step-navigation .help-number h2 {
  display: inline;
  margin: 0 15px 0 0;
}

#footer-banner #step-navigation button {
  display: inline-block;
  margin-left: 20px;
}

#footer-save-banner {
  text-align: center;
  font-size: 10px;
  padding: 0px 15px;
}

footer {
  text-align: center;
  background-color: #334259;
  color: white;
  padding: 10px 15px;
}

footer a {
  color: #bed12f;
  text-decoration: none;
  font-weight: 600;
}

footer a:hover {
  color: #4898bb;
}

/*---------------------------------------Tablet Styles*/
/*-------------------------------------------------------------------------------MIXINS*/
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*-------------------------------------------------------------------------------COLORS*/
/*------- MOST tablets in landscape orientation ------*/
/*------- MOST tablets in portrait orientation ------*/
/*---------------------------------------Phone Styles*/
/*-------------------------------------------------------------------------------MIXINS*/
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*-------------------------------------------------------------------------------COLORS*/
/*------- MOST phones in ------*/
@media only screen and (max-width: 749px) {
  body .body-container {
    padding: 55px 0px 0px;
  }
  button, .button {
    padding: 12px 20px;
  }
  nav {
    padding: 30px 0px 0px;
  }
  nav .step-progress .step {
    padding: 0px 1%;
  }
  nav .step-progress .step img {
    width: 55px;
  }
  nav .step-progress .step .status-bar {
    top: 27.5px;
  }
  .page .page-title {
    margin-bottom: 30px;
  }
  .page .page-title p {
    font-size: 14px;
  }
  .page .input-group {
    margin-bottom: 30px;
  }
  .page .col-four .input-row {
    width: calc(50% - 15px);
  }
  .page .col-four .input-row:nth-of-type(odd) {
    padding-right: 15px;
    padding-left: 0px;
  }
  .page .col-four .input-row:nth-of-type(even) {
    padding-left: 15px;
    padding-right: 0px;
  }
  .page .package {
    display: block;
    width: 100%;
  }
  .page .package > * {
    margin: 0px 0px 15px !important;
  }
  .page .package > * .heading {
    padding: 10px;
  }
  .page .package > * .pricing {
    padding: 10px;
  }
  .page .package > * .pricing .monthly {
    padding: 0px;
    border-right: 1px solid grey;
    border-bottom: 0px;
    display: inline-block;
    width: calc(50% - 1px);
  }
  .page .package > * .pricing .setup {
    padding: 0px;
    display: inline-block;
    width: calc(50% - 1px);
  }
  .page .package > * button {
    margin: 10px auto 0px;
  }
  .page .check-out {
    display: block;
  }
  .page .check-out > * {
    padding: 0px !important;
  }
  .page .check-out .package > * {
    margin: 0px !important;
    width: 100% !important;
  }
  .page .check-out .package.total {
    margin-top: 20px;
  }
  .page .check-out .cc-form {
    margin-top: 50px;
  }
  #footer-banner #step-navigation {
    text-align: center;
  }
  #footer-banner #step-navigation .help-number {
    display: inline-block;
    width: 100%;
    margin-bottom: 15px;
  }
  #footer-banner #step-navigation .help-number > * {
    font-size: 1.5em;
  }
}

@media only screen and (max-width: 499px) {
  h2 {
    font-size: 20px;
  }
  button.link, .button.link {
    font-size: 12px;
  }
  .input-row input:not([type="checkbox"]):not([type="button"]) {
    width: calc(100% - 22px);
    padding: 10px;
  }
  .select select {
    padding: 10px 35px 10px 15px;
  }
  .select:after {
    top: 17px;
  }
  .page {
    padding: 0px 20px;
  }
  .page .input-group .sub-group {
    padding: 0px 0px 15px 10px;
  }
  .page .col-two .input-row, .page .col-four .input-row {
    width: calc(100%);
  }
  .page .col-two .input-row:nth-of-type(odd), .page .col-four .input-row:nth-of-type(odd) {
    padding-right: 0px;
    padding-left: 0px;
  }
  .page .col-two .input-row:nth-of-type(even), .page .col-four .input-row:nth-of-type(even) {
    padding-left: 0px;
    padding-right: 0px;
  }
  .page .new-credential {
    display: block;
    margin-bottom: 15px;
  }
  .page .new-credential button {
    margin: 15px 0px;
  }
  .page .social-link, .page .shaw-service, .page .crm-service {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }
  .page .social-link:nth-of-type(odd), .page .social-link:nth-of-type(even), .page .shaw-service:nth-of-type(odd), .page .shaw-service:nth-of-type(even), .page .crm-service:nth-of-type(odd), .page .crm-service:nth-of-type(even) {
    padding: 0px;
  }
  .page .package {
    display: block;
  }
  .page .package > * .pricing .monthly span {
    font-size: 30px;
  }
  .page .conditions {
    padding: 15px;
    margin: 0px;
  }
}
